.loading-container {
    position: relative;

    .content {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

.parameter-column {
	width: ~"calc((100% - 60px)/2)";
}

.table-fixed {
	table-layout: fixed;
	width: 100%;
	white-space: nowrap;
}
.table-fixed td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}