.circle-pager {
	position: absolute;
	position: absolute;
	bottom: -45px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;

	.circle {
		height: 10px;
		width: 10px;
		background-color: lightgray;
		border-radius: 50%;
		display: inline-block;
		margin: 2px;

		&.active {
			background-color: @brand-primary;
		}
	}
}