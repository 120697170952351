html {
	position: relative;
	min-height: 100%;
}

body {
	/* Margin bottom by footer height */
	margin-bottom: 50px;
}

.mirror {
	-moz-transform: scale(-1, 1);
	-webkit-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	transform: scale(-1, 1);
	filter: FlipH;
}

canvas.clickable {
	padding: 3px;
}
canvas.clickable:hover {
	padding: 0;
	border: 3px solid @color-secondary;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

/* Color */
.text-default {
	color: @color-primary;
}

/* Aligner */

.text-nowrap {
	white-space: nowrap;
}

#wrapper {
	position: static;
}

@blur-amount: 5px;

.disable-panel {
	-webkit-filter: blur(@blur-amount);
	-moz-filter: blur(@blur-amount);
	-o-filter: blur(@blur-amount);
	-ms-filter: blur(@blur-amount);
	filter: blur(@blur-amount);
	pointer-events: none;
	user-select: none;
	position: relative;
}

.disabled-div {
	cursor: not-allowed !important;
	opacity: 0.65;
	filter: alpha(opacity=65);
	box-shadow: none;
	text-decoration: none;
}

.flex-row-container-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.flex-column-container-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.minutia-info {
	position: absolute;
	bottom: 0.75em;
	left: 1em;
	background-color: @color-primary;
	color: white;
	padding: 3px;
}

.scroll-x {
	max-width: 100%;
	overflow-x: scroll;
}
.scroll-y {
	max-height: 100%;
	overflow-y: scroll;
}

.table-clickable {
	/*	Intentional height. This ensures that table columns expand to 100% height
	*	instead of expanding the cell with padding */
	height: 1px;

	.td-anchor {
		padding: 0 !important;
		height: 100%;

		.inner {
			height: 100%;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 8px;
			text-decoration: none;
		}

		a.with-button {
			padding: 4px 8px;
		}
	}
}

a.reset-anchor:link,
a.reset-anchor:visited,
a.reset-anchor:hover {
	text-decoration: inherit;
	color: inherit;
	outline: 0;
}
