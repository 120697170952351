.scenario-step-box {
	border: 1px solid grey;
	padding: 5px;
}

.scenario-step-box:not(:first-child) {
	margin-top: 5px;
}

.scenario-step-row {
	line-height: 30px;
}