/* Align checkboxes with labels */

input[type=checkbox], input[type=radio] {
	vertical-align: middle;
	position: relative;
	bottom: 2px;
}

input[type=radio] {
	bottom: 2px;
}

input[type=radio].no-align-fix {
	bottom: unset;
}

.input-group-addon-select {
	padding: 0;

	.addon-select {
		height: 32px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1;
		color: #555555;
		text-align: center;
		background-color: #eeeeee;
	}
}

/* Form errors */

.has-error .control-label {
	color: @color-danger;
}
.has-error .form-control {
	border-color: @color-danger;
}

.has-error .input-group-addon {
	border: 1px solid @color-danger;
}

.has-error .form-control-feedback {
	color: @color-danger;
}

.flex-form {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: flex-start;
	flex-wrap: wrap;

	.form-field {
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 5px;
	}

	.self-center {
		align-self: center;
	}

	.form-actions {
		margin-left: auto;
		margin-right: 10px;
		padding-top: 5px;
	}

	.biometrics-form-field {
		flex: 1 0.5 40%;
	}

	.break-new-line {
		flex-basis: 100%;
	}
}

.username-field {
	position: relative;

	.username-not-found {
		left: 0;
		width: unset;
		top: 74%;
		font-size: 10pt;
		right: unset;
	}
}
