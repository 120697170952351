// Margin and Padding

each(map-keys(@grid-breakpoints), #(@breakpoint) {
	#media-breakpoint-up(@breakpoint, {
		@infix: breakpoint-infix(@breakpoint, @grid-breakpoints);

		each({ margin: fs-m; padding: fs-p; }, #(@abbrev, @prop) {
			each(@spacers, #(@length, @size) {
				.@{abbrev}@{infix}-@{size} { @{prop}: @length !important; }
				.@{abbrev}t@{infix}-@{size},
				.@{abbrev}y@{infix}-@{size} {
					@{prop}-top: @length !important;
				}
				.@{abbrev}r@{infix}-@{size},
				.@{abbrev}x@{infix}-@{size} {
					@{prop}-right: @length !important;
				}
				.@{abbrev}b@{infix}-@{size},
				.@{abbrev}y@{infix}-@{size} {
					@{prop}-bottom: @length !important;
				}
				.@{abbrev}l@{infix}-@{size},
				.@{abbrev}x@{infix}-@{size} {
					@{prop}-left: @length !important;
				}
			});
		});

		// Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
		each(@spacers, #(@length, @size) {
			& when not (@size = 0) {
				.fs-m@{infix}-n@{size} { margin: -@length !important; }
				.fs-mt@{infix}-n@{size},
				.fs-my@{infix}-n@{size} {
					margin-top: -@length !important;
				}
				.fs-mr@{infix}-n@{size},
				.fs-mx@{infix}-n@{size} {
					margin-right: -@length !important;
				}
				.fs-mb@{infix}-n@{size},
				.fs-my@{infix}-n@{size} {
					margin-bottom: -@length !important;
				}
				.fs-ml@{infix}-n@{size},
				.fs-mx@{infix}-n@{size} {
					margin-left: -@length !important;
				}
			}
		});

		// Some special margin utils
		.fs-m@{infix}-auto { margin: auto !important; }
		.fs-mt@{infix}-auto,
		.fs-my@{infix}-auto {
			margin-top: auto !important;
		}
		.fs-mr@{infix}-auto,
		.fs-mx@{infix}-auto {
			margin-right: auto !important;
		}
		.fs-mb@{infix}-auto,
		.fs-my@{infix}-auto {
			margin-bottom: auto !important;
		}
		.fs-ml@{infix}-auto,
		.fs-mx@{infix}-auto {
			margin-left: auto !important;
		}
	});
});
