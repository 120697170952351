.info-header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .to-right {
        margin-left: auto;
    }
}

.info-header {
    padding: 0 10px 0 10px;
    cursor: auto;

    h2 {
        margin: 0;

        &.leave-margin { 
            margin-top: revert;
            margin-bottom: 10px;
        }
    }

    .info-panel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        flex-wrap: wrap;
    }

    .info-panel-title {
        max-width: 600px;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .info-panel-details {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: baseline;
        flex-wrap: wrap;

        .details-panel {
            padding-left: 5px;
            font-size: 14pt;
            width: fit-content;
            margin: auto;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            box-sizing: border-box;
            text-overflow: ellipsis;

            .element-count {
                min-width: 25px;
                display: inline-block;
                text-align: center;
            }
        }
    }
}

.more-details-panel {
    display: inline-block;
    text-align: center;

    .more-details-header {
        font-size: 14pt;
        width: fit-content;
        margin: auto;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
        text-overflow: ellipsis;
    }
}

.panel-heading {
    .info-badge {
        &.bg-success { background-color: @brand-success }
        &.bg-warning { background-color: @brand-warning }
        &.bg-primary { background-color: @brand-primary }
        &.bg-secondary { background-color: @color-secondary }
        &.bg-info { background-color: @brand-info }
        &.bg-danger { background-color: @brand-danger }
    }
}

.more-details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.reject-info {
    position: relative;
    margin-top: 10px;

    .reject-view-more {
        position: relative;
        overflow: auto;
    }
    
    .info-reject-details {
        margin-top: 5px;
        border-top: 1px solid #ddd;
    
        .reject-details {
            margin-bottom: 5px;
            .details-label {
                font-weight: bold;
            }
    
            .details {
                font-size: 12pt;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .info-panels-container {
        justify-content: flex-start;
        
        .to-right {
            margin-left: unset;
        }
    }
}