.score-container-transactions {
	margin-left: 5px;
}

@media screen {
	@media (max-width: 1200px) {
		.score-container-transactions {
			padding-right: 22px;
		}
	}
}

.score-panel {
	border: 1px solid #ddd;
	border-radius: 5%;
	height: 50px;
    width: 110px;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;

	padding-bottom: 5px;

	.score-img-container {
		position: relative;

		.score-icon {
			font-size: 30px;
			position: relative;
		}
	}

	.score-info {
		position: relative;

		.info-modality {
			font-weight: bold;
		}
	}
}

@media screen {
	@media (max-width: 1200px) {
		.score-panel-container {
			display: flex;
			flex-direction: row;
			justify-content: space-around;

			border: 1.5px solid #ddd;
			border-bottom: none;
			border-top: none;
			border-radius: 5%;
		}
		.score-panel {
			border: none;
			flex-direction: column;
			height: auto;
			width: auto;
			justify-content: center;
			
			.score-img-container {
				display: none;
			}
			
			.score-info {
				.info-modality {
					font-weight: initial;
    				font-size: 13pt;
				}
			}
		}
	}
	@media (min-width: @screen-md-min) {
		.score-panel {
			width: 100px;

			.score-img-container {
				.score-icon {
					font-size: 25px;
				}
			}
		}
	}
}

.elem-container {
	&:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.elem {
		width: 100%;
		padding-bottom: 5px;
	}

	.elem-modality {
		float: left;
		font-weight: bold;
	}

	.elem:after {
		content: "";
		display: block;
		clear: both;
		border-bottom: 1px solid #ddd;
	}

	.elem-score {
		padding-right: 10px;
	}
}
