.navbar {
	margin-bottom: 0px;

    .navbar-header .navbar-brand {
        line-height: unset;
    }
}
.navbar-inverse a {
	color: #fff;
}
.navbar-inverse.sidebar li a:hover,
.navbar-inverse.sidebar li a:focus,
.navbar-inverse.sidebar li a:active,
.navbar-inverse.sidebar li.active {
	background-color: @color-sidebar-hover;
	color: darken(#ffffff, 20%);
}
.navbar-static-top {
	color: #ffffff;
	background-color: @color-header;
}

.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li {
    display: inline-block;
}

.navbar-top-links li:last-child {
    margin-right: 15px;
}

.navbar-top-links li a {
    padding: 15px;
    min-height: 50px;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}