/* Subject modal */
.subject-modal-window > .modal-dialog {
	max-height: ~"calc(100% - 60px)";
}
.fullscreen-modal-window > .modal-dialog {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
}
.fullheight-modal-window > .modal-dialog {
	height: auto;
}

.fit-content-modal > .modal-dialog {
	width: fit-content;
}

.modal-w-6 > .modal-dialog {
	width: 50%;
}

.modal-admin {
	text-align: center;

	.modal-dialog {
		max-width: 100%;
		width: auto !important;
		display: inline-block;
	}
}