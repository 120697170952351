@panel-admin-default-heading-bg: #ffffff;
@panel-admin-default-border: #ffffff;

.panel-admin {
    .panel-variant(@panel-admin-default-border; @panel-default-text; @panel-admin-default-heading-bg; @panel-admin-default-border);

    .panel-heading,
    .panel-footer {
        font-weight: bold;
        font-size: 125%;
        border-bottom: 1px solid rgba(41, 56, 70, 0.02);
    }

    &.panel-empty{
        border: none;
        box-shadow: none;
    }
    
    &.panel-empty .panel-heading{
        border: none;
        border-radius: 0;
    }
}
