#page-wrapper {
    padding: 0 15px;
    min-height: ~"calc(100vh - 120px)";
    background-color: #F7F7F7;
}

@media(min-width:768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 250px;
        padding: 0 30px;
        border-left: 1px solid darken(@gray-lightest, 6.5%);
        width: ~"calc(100% - 250px)";
    }
}