@blur-amount: 5px;

.disable-panel {
    -webkit-filter: blur(@blur-amount);
    -moz-filter: blur(@blur-amount);
    -o-filter: blur(@blur-amount);
    -ms-filter: blur(@blur-amount);
    filter: blur(@blur-amount);
    pointer-events: none;
    user-select: none;
}

.config-container {
    position: relative;
}

.config {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 40%;
    max-height: 40%;
    margin: auto;
    z-index: 3;
}

.update-status-btn {
	margin-right: 8.5%;
}

.admin-help-block.help-block.has-error {
    color: #a94442;
}