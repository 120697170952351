/* Page header */

#header {
	margin-top: 30px;
	line-height: 63px;
	.icon {
		width: 45px;
		height: 33px;
		margin-right: 5px;
	}
}

/* Logo */

#logo {
	background-color: @color-primary;
	line-height: 40px;
	a {
		text-decoration: none;
		color: whitesmoke;
	}
	img {
		width: 35px;
		height: 35px;
		margin-right: 10px;
	}
}