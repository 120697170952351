@import "~bootstrap/less/bootstrap.less";

@color-primary:						#293846;
@color-secondary:					#224d58;
@color-info:						#86b1bc;
@color-danger:						rgb(212, 63, 58);

@color-header:						@color-primary;
@color-sidebar:						darken(@color-primary, 8%);
@color-sidebar-hover:				darken(@color-primary, 11%);

@text-color:						@color-primary;

@brand-primary:						@color-primary;
@brand-info:						@color-secondary;
@alert-border-radius:				0px;

@btn-default-color:					@color-primary;

@border-radius-base:				0px;
@border-radius-large:				0px;
@border-radius-small:				0px;

@btn-border-radius-base:			0px;
@btn-border-radius-large:			0px;
@btn-border-radius-small:			0px;

@badge-border-radius:				3px;

@input-border-radius:				0px;
@input-border-radius-large:			0px;
@input-border-radius-small:			0px;
@input-border:						#224d58;

@state-success-text:				#ffffff;
@state-success-bg:					#48a448;

@state-info-text:					#ffffff;
@state-info-bg:						@color-secondary;

@state-warning-text:				#8a6d3b;
@state-warning-bg:					#fcf8e3;

@state-danger-text:					#f2dede;
@state-danger-bg:					#d43f3a;

@gray-darker: lighten(#000, 13.5%);
@gray-dark: lighten(#000, 20%);
@gray: lighten(#000, 33.5%);
@gray-light: lighten(#000, 60%);
@gray-lighter: lighten(#000, 93.5%);
@gray-lightest: lighten(#000, 97.25%);

@modal-lg:							1200px;
@modal-md:							80%;
@modal-sm:							100%;

@jumbotron-padding:					20px;
@jumbotron-color:					white;
@jumbotron-bg:						@color-secondary;

@panel-footer-bg:					#ffffff;

@grid-columns: 12;
@grid-gutter-width: 30px;

@image-blur:						1rem;

.jumbotron {
	margin-top: 100px;
	border-radius: 0px !important;
}

.text-success {
	color: lighten(@brand-success, 2%);
}
.text-warning {
	color: lighten(@brand-warning, 2%);
}
.text-danger {
	color: lighten(@brand-danger, 2%);
}

/* Modal */

.modal-header {
	background-color: @color-primary;
	color: white;
	button {
		color: white;
		opacity: 1;
	}
	button:focus, button:hover {
		color: #ccc;
	}
}

/* Buttons */
.btn {
	&.btn-default {
		&.selected {
			border-color: lighten(@color-primary, 20%);
			box-shadow: 0 1px 1px gray;
		}
	}

	&.btn-outline {
		// Inherit .btn-default class
		&:extend(.btn-default all);
		border-color: @color-primary;

		&.active {
			&:hover,
			&:focus {
				color: white;
				background-color: darken(@color-primary, 15%);
			}

			background-color: #293846;
			color: white;
		}
	}

	&.btn-edgeless {
		// Inherit .btn-default class
		&:extend(.btn-default all);

		border-radius: 10px;
		border-color: @color-primary;
	}
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
	border: none;
}

table > tbody > tr > td.table-min-column {
	width: 1%;
	white-space: nowrap;
}

table > thead > tr > th.sortable-header {
	position: relative;
	vertical-align: middle;
	.header-text {
		margin-right: 10px;
	}

	.sort-icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 3px;
	}
}

.tooltip-info {
	+ .tooltip {
		width: 200px;

		> .tooltip-inner {
			width: 190px;
		}
	}
}
