.badge-danger {
    background-color: @brand-danger
}

.badge-warning {
    background-color: @brand-warning;
}

.badge-success {
    background-color: @brand-success;
}

.badge-info {
    background-color: @brand-info;
}

.badge-primary {
    background-color: @color-primary;
}

.badge-secondary {
    background-color: @color-secondary;
}