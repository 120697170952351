#scroll-top {
	width: 56px;
	font-size: 24px;
	position: fixed;
	bottom: 10px; /* depends on scrollTop directive */
	right: 15px;
	outline: none;
	padding: 10px;
	border-radius: 5px;
	opacity: 0.75;
}