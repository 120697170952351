.biographic-item:hover {
	background-color: rgb(253, 253, 253);
}

.selected-item {
	background-color: whitesmoke;
}

.key-value-error {
	background-color: #a94442;
	color: white;
}

.arrow-button {
	color: #224d58;
}

.arrow-button:hover {
	color: #293846;
}

.arrow-button:active {
	color: black;
}

.arrow-button-disabled {
	color: rgb(177, 177, 177);
}

.trash-button {
	color: #a94442
}

.trash-button:hover {
	color: #943c3a
}

.required-unselected {
	color: #DCD9CD;
}
.required-unselected:hover {
	color: rgb(177, 177, 177);
}

.required-selected {
	color: #224d58
}

.trash-button:active {
	color: rgb(146, 59, 58)
}