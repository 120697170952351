/* Print */

@media print {
	body header,
	body footer,
	body section:not(.section-to-print) {
		display: none;
	}
	.dont-print {
		display: none;
	}
}