.chart-cursor {
    cursor: pointer;
}
.chart-canvas{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 999;
    width: fit-content;
}
.chartjs-tooltip.bottom {
    -webkit-transform: translate(-50%, 10%);
    transform: translate(-50%, 10%);
}
.chartjs-tooltip.bottom::before {
    border: solid;
    border-color: #111 transparent;
    border-color: rgba(0, 0, 0, .8) transparent;
    border-width: 0 8px 8px 8px;
    bottom: 1em;
    content:"";
    display: block;
    left: 50%;
    top: 0;
    position: absolute;
    z-index: 2;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    width: 10px;
    height: 10px;
}
.chartjs-tooltip.top {
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
}
.chartjs-tooltip.top::before {
    border: solid;
    border-color: #111 transparent;
    border-color: rgba(0, 0, 0, .8) transparent;
    border-width: 8px 8px 0 8px;
    bottom: 1em;
    content: "";
    display: block;
    left: 50%;
    top: 100%;
    position: absolute;
    z-index: 2;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 10px;
    height: 10px;
}
.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.chart-no-data-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
    z-index: 100;

    .chart-no-data-text {
        background-color: #f5f5f5;
        padding: 5px;
        color: black;
        border: 1px solid #ddd;
        font-weight: bold
    }
}

.chart-no-data {
    display: block;
    width: 344px;
    height: 172px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.chart-loading-container {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%
}

.chart-loading {
    background-color: #f5f5f5;
    padding: 5px;
    color: black;
    border: 1px solid #ddd;
    font-weight: bold
}
