/* Subject biometrics indicators */

@color-indicator-default: darken(#f5f5f5, 5%);
@color-indicator: lighten(@color-primary, 15%);

.position-indicator {
	max-height: 10px;
}

#ind-face .ind-face,
#ind-left-eye .ind-left-eye,
#ind-right-eye .ind-right-eye,

#ind-left-thumb .ind-left-finger,
#ind-left-index-finger .ind-left-finger,
#ind-left-middle-finger .ind-left-finger,
#ind-left-ring-finger .ind-left-finger,
#ind-left-little-finger .ind-left-finger,

#ind-right-thumb .ind-right-finger,
#ind-right-index-finger .ind-right-finger,
#ind-right-middle-finger .ind-right-finger,
#ind-right-ring-finger .ind-right-finger,
#ind-right-little-finger .ind-right-finger,

#ind-plain-left-four-fingers .ind-left-finger,
#ind-plain-right-four-fingers .ind-right-finger,
#ind-plain-thumbs .ind-left-finger,
#ind-plain-thumbs .ind-right-finger {
	fill: @color-indicator-default;
}

#ind-face .face { fill: none; }

#ind-left-eye .left-eye { fill: @color-indicator; }
#ind-right-eye .right-eye { fill: @color-indicator; }

#ind-left-little-finger .left-little-finger { fill: @color-indicator; }
#ind-left-ring-finger .left-ring-finger { fill: @color-indicator; }
#ind-left-middle-finger .left-middle-finger { fill: @color-indicator; }
#ind-left-index-finger .left-index-finger { fill: @color-indicator; }
#ind-left-thumb .left-thumb { fill: @color-indicator; }

#ind-right-little-finger .right-little-finger { fill: @color-indicator; }
#ind-right-ring-finger .right-ring-finger { fill: @color-indicator; }
#ind-right-middle-finger .right-middle-finger { fill: @color-indicator; }
#ind-right-index-finger .right-index-finger { fill: @color-indicator; }
#ind-right-thumb .right-thumb { fill: @color-indicator; }

#ind-left-palms .ind-left-palms { fill: @color-indicator; }
#ind-right-palms .ind-right-palms { fill: @color-indicator; }

#ind-plain-left-four-fingers {
	.left-little-finger,
	.left-ring-finger,
	.left-middle-finger,
	.left-index-finger {
		fill: @color-indicator;
	}
}

#ind-plain-right-four-fingers {
	.right-little-finger,
	.right-ring-finger,
	.right-middle-finger,
	.right-index-finger {
		fill: @color-indicator;
	}
}

#ind-plain-thumbs {
	.left-thumb,
	.right-thumb {
		fill: @color-indicator;
	}
}