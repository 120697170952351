/* Home page tiles */

.tile {
	transition: 1.0s;
	background-color: @color-secondary;
	border: 2px solid @color-primary;
	padding-bottom: 50%;
	position: relative;
	margin-bottom: 2em;

	.tile-content {
		padding-bottom: 50%;
		position: relative;
		color: white;
		.content {
			position: absolute;
			width: 50%; // IE
			top: 75%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.tile-text {
		font-size: 1.8em;
		color: white;
		position: absolute;
		top: 85%;
		left: 50%;
		transform: translate(-50%, -100%);
		width: 100%;
		text-align: center;
	}
	.tile-text-sm {
		font-size: 1.5em;
		color: white;
		position: absolute;
		top: 85%;
		left: 50%;
		transform: translate(-50%, -100%);
		width: 100%;
		text-align: center;
	}
	.tile-status {
		white-space: nowrap;
		font-size: 1.0em;
		position: absolute;
		top: 85%;
		left: 80%;
		transform: translate(-50%, 0%);
		color: white;
	}
}

@media (max-width: @screen-xs-min) {
	.tile .tile-text {
		font-size: 1.1em;
	}
}

@media (max-width: @screen-md-min) {
	.tile .tile-text {
		font-size: 1.5em;
	}
}

.tile:hover {
	background-color: lighten(@color-secondary, 10%);
	border: 2px solid @color-primary;
}