.progress-bar-marked {
	background-color: lighten(@brand-success, 5%);
}
.progress-bar-unmarked {
	background-color: lighten(@brand-danger, 5%);
}

select.hide-arrow {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 0px;
	padding: 0px 4px;
	text-overflow: '';
	line-height: normal;
}

.whitesmoke-panel {
	background-color: whitesmoke;
	padding: 15px;
}

.panel-info {
	select {
		color: @color-primary;
	}
}

.panel-default .panel-heading,
.panel-info .panel-heading {
	font-weight: bold;
	font-size: 125%;
	border-bottom: 1px solid fade(@color-primary, 5%);
}
.panel-default .panel-footer,
.panel-info .panel-footer {
	border-top: none;
}

.text-success {
	color: lighten(@brand-success, 5%);
}
.text-warning {
	color: lighten(@brand-warning, 5%);
}
.text-danger {
	color: lighten(@brand-danger, 5%);
}

/* Main */

@media (min-width: 1400px) {
	.container {
		width: 1370px;
	}
}

.clickable,
a[ng-click] {
	cursor: pointer;
}

.panel-inline {
	display: inline-block;
	vertical-align: top;
	margin: 5px;
}

.panel-modality > .panel-heading,
.panel-modality > .panel-footer {
	padding: 2px 8px;
	text-align: center;
}

.panel-modality > .panel-body {
	padding: 2px;
}

.panel-modality > .panel-body > img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	max-height: 100%;
}

.panel-face {
	width: ~"calc(100% - 10px)";
	padding: 5px;
}

.panel-face > .panel-body {
	height: 280px;
}

.panel-for-face {
	width: ~"calc(100% - 10px)";
	padding: 5px;
}

.panel-iris {
	width: ~"calc(50% - 10px)";
	// padding: 5px;
}

.panel-iris > .panel-body {
	height: 90px;
}

.panel-finger {
	width: ~"calc(20% - 10px)";
}

.panel-finger > .panel-body {
	height: 90px;
}

.panel-slap {
	width: ~"calc(33% - 10px)";
	padding: 5px;
}

.panel-slap > .panel-body {
	height: 90px;
}

.panel-comment-data {
	padding: 5px;

	.panel-body {
		height: 280px;
	}
}

.panel-comment-data,
.panel-demographic-data {
	width: ~"calc(100% - 10px)";
}

.panel-demographic-data > .panel-body {
	padding: 0px;
}

.panel-demographic-data .table > tbody > tr:first-child > td {
	border-top: 0px;
}

.panel-demographic-data table {
	margin-bottom: 0px;
}

.modal-dynamic {
	margin: 20px;
	width: auto;
	height: ~"calc(100% - 40px)";
}

.modal-dynamic > .modal-content {
	height: 100%;
}

.modal-dynamic > .modal-content > .modal-header {
	font-weight: bold;
	font-size: 125%;
	height: 51px; /* 15+15 for padding, 20 for content, 1 for bottom border */
	text-align: center;
}

.modal-dynamic > .modal-content > .modal-body  {
	height: ~"calc(100% - 51px)";
}

.simple-comparison-body * {
	height: 100%;
}

.simple-comparison-body > table {
	width: 100%;
}

.simple-comparison-body > table td {
	text-align: center;
	padding: 2px;
	width: 50%;
}

.simple-comparison-body > table img {
	height: initial;
	max-width: 100%;
	max-height: 100%;
}

img.fill-width {
	min-width: 100%;
}

img.fill-height {
	min-height: 100%;
}

.finger-modal-body {
	position: relative;
}

.finger-modal-body > div {
	display: inline-block;
	vertical-align: top;
}

.finger-modal-body .finger-history-comments-button {
	position: absolute;
	bottom: 15px;
	right: 19px;
}

.finger-modal-body .finger-history-comments-button {
	position: absolute;
	bottom: 15px;
	right: 19px;
}

.finger-modal-side {
	overflow: auto;
	height: 100%;
	padding-right: 10px;
	width: 180px;
}

.finger-modal-side > table {
	margin-bottom: 0px;
}

.finger-modal-main {
	width: ~"calc(100% - 180px - 8px)";
	height: 100%;
}

.finger-canvas {
	position: relative;
	overflow: hidden;
	height: 100%;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.finger-canvas .probe-finger-drawing-options {
	position: absolute;
	top: 5px;
	left: 15px;
}

.finger-canvas .hit-finger-drawing-options {
	position: absolute;
	top: 5px;
	right: 15px;
}

// TODO: Rename.
.finger-canvas .face-drawing-overlay-slider {
	position: absolute;
	bottom: 5px;
	left: 95px;
	width: ~"calc(100% - 15px - 15px - 80px)";
}

.finger-drawing-options {
	display: inline-block;
	width: ~"calc(50% - 2px)";
}

.finger-drawing-options > .title {
	margin-right: 6px;
}

.dropdown-with-inputs {
	padding-left: 10px;
	padding-right: 10px;
}

.dropdown-with-inputs .dropdown-header {
	margin-top: 15px;
	margin-bottom: 5px;
	font-size: 13px;
	font-weight: bold;
}

.dropdown-with-inputs .dropdown-header:first-child {
	margin-top: 3px;
}

.dropdown-with-inputs .dropdown-header label {
	font-weight: bold;
}

.dropdown-header.checkbox {
	padding: 3px 0px;
	margin-bottom: 15px;
}

.dropdown-header.checkbox input[type="checkbox"] {
	margin-top: 2px;
}

.semi-transparent {
	background-color: rgba(248, 248, 248, .70);
}

.btn-finger-color {
	height: 28px;
	width: 100%;
}

.btn-finger-action {
	display: block;
	margin-bottom: 8px;
}

.fingers-container > .panel-hand {
	position: relative;
	margin: 5px;
	overflow: hidden;
}

.fingers-container > .panel-hand:first-child {
	margin-bottom: 10px;
}

.fingers-container > .panel-hand > .panel-body {
	padding: 2px;
}

.fingers-container > .panel-hand > .panel-heading {
	position: absolute;
	width: 1000px;
	text-align: right;

	-webkit-transform-origin: right top;
	-moz-transform-origin: right top;
	-ms-transform-origin: right top;
	-o-transform-origin: right top;
	transform-origin: right top;

	-webkit-transform: rotate(-90deg) translate(0px, -1000px);
	-moz-transform: rotate(-90deg) translate(0px, -1000px);
	-ms-transform: rotate(-90deg) translate(0px, -1000px);
	-o-transform: rotate(-90deg) translate(0px, -1000px);
	transform: rotate(-90deg) translate(0px, -1000px);
}

.panel-comments {
	width: ~"calc(20% - 10px)";
	height: ~"calc(100%)";
}

/*.main-container {
	margin: 10px;
}*/

.comments-text {
	height: 100%;
	width: 100%;
	resize: none;
	border: none;
}

.operator-comments {
	margin-left: 5px;
	margin-right: 5px;
	max-height: 230px;
	overflow-y: auto;
}

.adjudication-status {
	text-align: center;
	width: 100%;
	font-size: 14pt;
	padding: 8px;
	color: white;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.12);

	&.alert-warning {
		background-color: @brand-warning;
	}
}

/* Page slide */

#btn-pageslide {
	position: fixed;
	bottom: 0px;
	right: 25px;
	z-index: 1052;
}
#pageslide {
	border-left: 1px solid @color-indicator;
	background-color: rgb(255, 255, 255);
	.content {
		margin-top: 30px
	}
}


.borderBase {
	border: solid;
}

.score-excellent {
	background-color: #08519c;
	color: white;
}
.score-very-good {
	background-color: #3182bd;
	color: white;
}
.score-good {
	background-color: #6baed6;
	color: white;
}
.score-poor {
	background-color: #d1ebfb;
	color: black;
}
.score-bad {
	background-color: #eff3ff;
}
.score-very-bad {
	display: none;
}

.score-card {
	position: absolute;
	bottom: 0px;
	left: 0px;
	padding-left: 5px;
	padding-right: 5px;
}

// . {
//     background-color: red;
// }

.borderBase {
	border: solid;
}

.border-excellent {
	.borderBase;
	border-color: #08519c;
	color: white;
}
.border-very-good {
	.borderBase;
	border-color: #3182bd;
	color: white;
}
.border-good {
	.borderBase;
	border-color: #6baed6;
	color: white;
}
.border-poor {
	.borderBase;
	border-color: #d1ebfb;
	color: black;
}
.border-bad {
	.borderBase;
	border-color: #eff3ff;
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 1;
	height: 100%;
	width: 100%;
	transition: .5s ease;
	background: repeating-linear-gradient(
	45deg,
	rgba(0, 0, 0, 0.2),
	rgba(0, 0, 0, 0.2) 10px,
	rgba(0, 0, 0, 0.3) 10px,
	rgba(0, 0, 0, 0.3) 20px
	),
}

.img-mirror {
	-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
}

.biometric-hover-effect {
	.transition (@property, @time) {
		-webkit-transition: @property, @time;
		-moz-transition: @property, @time;
		-ms-transition: @property, @time;
		-o-transition: @property, @time;
	}

	@transition-time: 0.3s;
	background-color: fade(@color-primary, 30%);
	.transition(background-color, @transition-time);
	// border-radius: 5%;
	// transform: translateY(-2px);
	// .transition(transform, @transition-time);
}

@media only screen and (max-width: 1400px) {
	.adj-navig {
		transform: translateY(50px);
	}
}
@media only screen and (max-width: 1200px) {
	.adj-navig {
		transform: unset;
	}
}

.navig-buttons {
	width: 100%;
	position: absolute;
	left: 0px;

	&.nb-adjudication {
		top: -70px;
		padding-left: 10px;
		padding-right: 0;

		.navig-buttons-locked {
			top: -60px;
		}

		.encounter-selection {
			left: -15px;
			z-index: 1;

			select {
				border: 1px solid #ddd;
				cursor: pointer;
			}
		}
		.navigation-buttons {
			right: 25px;
		}
		.navig-progress-bar {
			right: 15px;
			padding-right: 25px;
		}
	}
	&.nb-transaction {
		top: -50px;
		padding-left: 20px;
		padding-right: 20px;

		.encounter-selection {
			left: -15px;
			z-index: 1;

			select {
				border: 1px solid #ddd;
				cursor: pointer;
			}
		}
		.navigation-buttons {
			right: 25px;
		}
		.navig-progress-bar {
			right: 15px;
			padding-right: 25px;
		}
	}
}

@media screen and (max-width: 900px) {
	.responsive-hit {
		margin-top: 80px;
	}
}

.sides-heading {
	border-top: 3px solid @color-primary;
	margin-bottom: -8px;
	position: relative;
	z-index: 999;

	display: flex;
	justify-content: flex-start;

	&.sides-heading-iris {
		margin-bottom: -20px;
	}

	&.sides-heading-right {
		justify-content: flex-end;

		.heading-title {
			border-radius: 0 0 0 25px;
			padding-left: 15px;
			padding-right: 5px;
		}
	}

	.heading-title {
		width: min-content;
		background-color: @color-primary;
		color: white;
		border-radius: 0 0 25px 0;
		padding-left: 5px;
		padding-right: 15px;
		font-weight: bold;
		margin-top: -2px;
	}
}
