/* Alerts */

.alert.ng-hide-add,
.alert.ng-hide-remove {

	-moz-transition: 0.5s linear all;
	-o-transition: 0.5s linear all;
	-webkit-transition: 0.5s linear all;
	transition: 0.5s linear all;

	display: block !important;
	opacity: 1;
}

.alert.ng-hide {
	opacity: 0;
}

.alerts-top {
	position: fixed;
	top: 45px;
	width: 500px;
	left: 50%;
	margin-left: -250px;
	z-index: 1051;
}

.alerts-top .alert {
	opacity: 0.9;
	box-shadow: 2px 2px 2px rgba(100, 100, 100, 0.5);
}

.alerts-top .alert.ng-enter,
.alerts-top .alert.ng-leave {
	-webkit-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
	-moz-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
	-ms-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
	-o-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
	transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
	position: relative;
	display: block;
}

.alerts-top .alert.ng-enter.ng-enter-active,
.alerts-top .alert.ng-leave {
	opacity: 1;
	top: 0px;
}

.alerts-top .alert.ng-leave.ng-leave-active,
.alerts-top .alert.ng-enter {
	opacity: 0;
	top: -70px;
}
