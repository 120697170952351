@timeline-height: 180px;

@ball-width: 20px;

@dotted-line-width: 3px;
@dotted-line-height: 20px;

@ball-offset-from-center: 100px;
@ball-offset-from-edges: 20px;

@default-ball-dotted-line-color: #eee;
@ball-border-default-color: #ddd;
@default-ball-text-color: #777777;

@ball-header-offset-top: 25px;
@ball-date-offset: 25px;

@checkpoint-line-height: 130px;
@checkpoint-line-offset: 10px;

.timeline-line {
	position: relative;

	&::before {
		position: absolute;
		left: 50%;
		margin-left: -50%;
		top: 44%;
		width: 100%;
		height: 3px;
		background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, #eee 65px, #eee 50%, #eee calc(100% - 65px), rgba(0, 0, 0, 0) 100%);
		content: '';
	}
}

.timeline {
	overflow-x: auto;
	overflow-y: hidden;
	position: relative;
	width: 100%;
	height: @timeline-height;

	.items {
		margin: auto;
		width: max-content;
		height: 100%;

		.item {
			position: relative;
			height: 100%;
			width: 90px;
			display: inline-block;
			margin: 0 40px;

			.ball-container {
				width: @ball-width + 3px;
				height: @ball-width + 3px;
				position: relative;
				padding-top: 35px;
				height: auto;
				margin: auto;

				.ball {
					position: relative;
					background-color: whitesmoke;
					border: 2px solid @ball-border-default-color;
					border-radius: 50%;
					height: @ball-width;
					width: @ball-width;
					transition: all .5s;

					&:hover {
						border-color: @color-primary;
					}
	
					&.focus {
						border-color: @ball-border-default-color;
						background-color: @color-primary;
					}

					&::after {
						position: absolute;
						height: @dotted-line-height;
						left: @ball-width / 2 - @dotted-line-width;
						top: @ball-width;
						content: '';
						border-left: @dotted-line-width dotted @default-ball-dotted-line-color;
					}

					.ball-date {
						bottom: -70px;
						font-size: 9pt;
						position: absolute;
						width: max-content;
						transform: translateX(-50%);
						left: 50%;
						color: @default-ball-text-color;
					}
				}
	
				&.bottom {
					padding-top: 107px;
	
					.ball {
						&::after {
							top: -(@dotted-line-width + @ball-width);
						}

						.ball-header-container {
							top: @ball-header-offset-top;
						}

						.ball-date {
							bottom: 44px;
						}
					}
				}
			}

			.checkpoint-ball-container {
				width: @ball-width + 3px;
				height: @ball-width + 3px;
				position: relative;
				padding-top: 70px;
				height: auto;
				margin: auto;

				.ball-checkpoint {
					position: relative;
					background-color: @ball-border-default-color;
					border: 2px solid whitesmoke;
					border-radius: 50%;
					height: @ball-width;
					width: @ball-width;
					transition: all .5s;

					&:hover {
						border-color: @color-primary;
					}

					.ball-date {
						bottom: -35px;
						font-size: 9pt;
						position: absolute;
						width: max-content;
						transform: translateX(-50%);
						left: 50%;
						color: @default-ball-text-color;
					}
				}
			}

			.ball-header-container {
				position: absolute;
				width: max-content;
				top: -@ball-header-offset-top;
				left: 50%;

				.ball-header {
					position: relative;
					transform: translateX(-50%);
					color: black;
				}
			}

			.checkpoint {
				position: relative;
				height: 100%;

				.line {
					position: absolute;
					height: @checkpoint-line-height;
					top: (@timeline-height - @checkpoint-line-height - @checkpoint-line-offset) / 2;
					border-left: 2px dashed darken(@default-ball-dotted-line-color, 15%);
				}

				.name {
					position: absolute;
					width: max-content;
					height: fit-content;
					left: 5px;
					top: (@timeline-height - @checkpoint-line-height - @checkpoint-line-offset) / 2;

					&.focus .badge.badge-default,
					&.focus .badge.badge-default:hover {
						background-color: @color-primary;
						color: white;
					}

					.badge.badge-default {
						background-color: whitesmoke;
						color: black;
						transition: all .5s;

						&:hover {
							background-color: darken(whitesmoke, 15%);
							color: @color-primary;
						}
					}
				}
			}
		}
	}
}

.timeline-event {
	position: relative;

	.date {
		margin-top: 0;
	}

	.fit-no-wrap {
		max-width: 100%;
		white-space: nowrap;
	}

	.info-icon {
		padding-right: 5px;
	}

	.event-details {
		border: none;
		margin-bottom: 0;

		.event-comment {
			overflow-y: scroll;
			max-height: 200px;
			padding-right: 10px;
			text-align: justify;
		}
	}
}

.timeline-legend {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 10px;

	.legend-item {
		margin-right: 10px;
	}
}

.timeline-vertical-container {
	position: relative;

	.timeline-line-vertical {
		position: relative;

		&::before {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 3px;
			height: 100%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #eee 65px, #eee 50%, #eee calc(100% - 65px), rgba(0, 0, 0, 0) 100%);
			content: '';
		}

		.timeline-vertical {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.item {
				min-height: 120px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				position: relative;
				margin-bottom: 10px;

				.timeline-panel {
					position: absolute;
					right: ~"calc(50% + 25px)";
					top: 50%;
					transform: translateY(-50%);
					border-radius: 20px;

					.panel-body {
						padding-bottom: unset;
					}

					&:before {
						content: "";
						float: right;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: -10px;
						width: 0;
						height: 0;
						border-top: 10px solid transparent;
						border-bottom: 10px solid transparent;
						border-left: 10px solid #ddd;
					}
					
					&:after {
						content: "";
						float: right;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: -9px;
						width: 0;
						height: 0;
						border-top: 9px solid transparent;
						border-bottom: 9px solid transparent;
						border-left: 9px solid white;
					}

					&.right {
						left: ~"calc(50% + 25px)";
						right: unset;

						&:before {
							left: -10px;
							right: unset;
							border-right: 10px solid #ddd;
							border-left: unset;
						}
						
						&:after {
							left: -9px;
							right: unset;
							border-right: 9px solid white;
							border-left: unset;
						}
					}

					.timeline-link {
						position: relative;
						overflow: hidden;

						a {
							float: right;
							font-size: 10pt;
							text-decoration: underline;
							padding-bottom: 5px;
						}
					}
				}

				.id-ball {
					display: inline-block;
					vertical-align: top;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					margin-right: 10px;
					background-color: @color-primary;
				}

				.content {
					display: inline-block;

					.header {
						display: inline-block;
						margin: 0;
						vertical-align: middle;
					}
				}

				.timeline-checkpoint {
					position: absolute;
					right: ~"calc(50% + 25px)";
					top: 50%;
					transform: translateY(-50%);

					&.right {
						left: ~"calc(50% + 25px)";
						right: unset;
					}

					.header {
						font-weight: bold;
					}
				}

				.ball-container {
					width: @ball-width + 3px;
					height: @ball-width + 3px;
					position: relative;
					margin: auto;
	
					.ball {
						position: relative;
						background-color: whitesmoke;
						border: 2px solid @ball-border-default-color;
						border-radius: 50%;
						height: @ball-width;
						width: @ball-width;
						transition: all .5s;
						margin: auto;
	
						&:hover {
							border-color: @color-primary;
						}
		
						&.focus {
							border-color: @ball-border-default-color;
							background-color: @color-primary;
						}
					}
				}

				.ball-date {
					position: absolute;
					left: ~"calc(50% + 25px)";
					font-style: italic;
					font-size: 9pt;

					&.right {
						right: ~"calc(50% + 25px)";
						left: unset;
						text-align: right;
					}
				}
			}
		}
	}
}
