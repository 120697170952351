.modality-options-container {
    display: flex;
    flex-direction: row;
}

.modality-options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 300px;

    .modality-radio:last-child  {
        margin-bottom: 0px;
    }
}

.modality-radio {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 0px;
    transition: border .3s;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    > label {
        padding: 10px;
        width: 100%;
        margin-left: 20px;
    }

    hr {
        border-left: 1px solid #eeeeee;
        width: 0;
        height: 100%
    }

    .radio-suffix {
        padding: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        span {
            padding-right: 5px;
            font-weight: bold;
        }
    }

    &.radio-active {
        border: 1px solid @brand-primary;
        border-radius: 5px;
    }
}

.option-description {
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-width: 300px;
    max-width: 700px;
    height: fit-content;
    padding-bottom: 15px;

    .option-icon {
        font-size: 36pt;
        height: auto;
        text-align: center;
    }

    .description {
        text-align: center;
    }
}

@media (max-width: @screen-md-max) {
    .modality-options-container {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
    }

    .modality-options {
        flex: 1;
        width: unset;
    }

    .option-description {
        margin-left: 0px;
        max-width: unset;
        margin-bottom: 10px;
    }
}