.detached-tabs {
	&.single-tab {
		width: min-content;

		.nav .nav-item {
			.nav-link {
				border: none;
				margin-right: 0;
			}
		}
	}
	.nav .active .nav-link {
		background-color: whitesmoke;
		border-bottom-color: whitesmoke;

		font-weight: bold;

		&:hover, &:focus {
			border-bottom-color: whitesmoke;
		}
	}
}

// ellipsis text overflow option for table cells
.ellipsis {
	position: relative;
	
	&:before {
		content: '&nbsp;';
		visibility: hidden;
	}
	
	.ellipsis-cell-content {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.reject-column {
	width: 400px;
}

.filter-field {
	margin-left: 10px;
	margin-right: 10px;
}

.transaction-statistic {
	padding: 5px;
	margin-left: 10px;
	margin-right: 10px;
	border: 1px solid transparent;
	border-radius: 20px;
	background-color: transparent;
	transition: all 0.5s;

	&.active {
		border-color: whitesmoke;
		background-color: whitesmoke;
	}

	&:hover {
		border-color: whitesmoke;
		background-color: whitesmoke;

		small {
			font-size: 80%;
		}
	}
	
	small {
		transition: all 0.5s;
	}
}
