// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

& when (@enable-grid-classes) {
	// Single container class with breakpoint max-widths
	.fs-container,
	// 100% wide container at all breakpoints
	.fs-container-fluid {
		#make-container();
	}

	each(@container-max-widths, #(@container-max-width, @breakpoint) {
		.fs-container-@{breakpoint} {
			&:extend(.fs-container-fluid all);
		}

		#media-breakpoint-up(@breakpoint, {
			// LESS PORT: Unfortunately we have to use an actual selector here in order to be able to
			// `:extend()` it later. This means that the selector is output in the compiled CSS,
			// creating a small disparity between the Less and Sass versions.
			// LESS PORT: Less cannot currently extend interpolated selectors, however, when a plain
			// selector is used this appears to compile correctly anyway. Because of this, the
			// `-@{breakpoint}` suffix is omitted from this selector.
			\%responsive-container {
				max-width: @container-max-width;
			}

			each(@grid-breakpoints, #(@width, @name) {
				& when ((@container-max-width > @width) or (@breakpoint = @name)) {
					// LESS PORT: A variable is required here to do the selector interpolation, since an
					// expression cannot be interpolated like in the Sass version.
					@infix: breakpoint-infix(@name, @grid-breakpoints);
					.fs-container@{infix} {
						// LESS PORT: Less cannot currently extend interpolated selectors, however, when a
						// plain selector is used this appears to compile correctly anyway. Because of
						// this, the `-@{breakpoint}` suffix is omitted from this selector.
						&:extend(\%responsive-container);
					}
				}
			});
		}, @grid-breakpoints);
	});
}

// Row
//
// Rows contain your columns.

.fs-row when (@enable-grid-classes) {
	#make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.fs-no-gutters when (@enable-grid-classes) {
	margin-right: 0;
	margin-left: 0;

	> .fs-col,
	> [class*='fs-col-'] {
		padding-right: 0;
		padding-left: 0;
	}
}

// Columns
//
// Common styles for small and large grid columns

& when (@enable-grid-classes) {
	#make-grid-columns();
}
