.comment-name {
    margin-top: -5px;
    font-weight: bold;
}

.comment-new-text {
    word-wrap: break-word;
    max-width: 100%;
    max-height: 235px;
}

.comment-text-toggle {
    color: @color-primary;
    text-decoration: underline;
    font-size: 9pt;
}

.comment-date {
    padding-bottom: 10px;
    font-size: 8pt;
}

.comment-new {
    resize: none;
    margin-bottom: -10px;
    border: none;

    &:focus {
        box-shadow: unset;
    }
}

.comment-label-container {
    line-height: initial;
}

.comments-scroller-auto {
    overflow-y: auto;
    height: calc(~"100vh - 110px");
}

.comments-scroller-container {
    height: 300px;
}

.comment-button-group {
    position: relative;
    padding: 2px;

    .biographic-field-badge {
        position: absolute;
        padding: 0px 4px;
        top: 11px;
        right: 3px;
        border-radius: 100%;

        font-size: 7pt;
        font-weight: bold;
        color: whitesmoke;
    }

    .comment-button {
        font-size: 16pt;
    }
}