@media (min-width:768px) {
	#sidebar-decoy {
		position: fixed;
		width: 250px;
		height: 10000px;
        background-color: @color-sidebar;
        z-index: -1;
	}
}

.sidebar {
    .sidebar-nav.navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
}

.sidebar .sidebar-search {
    padding: 15px;
}

.sidebar ul li.active {
    /*border-bottom: 1px solid darken(@gray-lightest, 6.5%);*/
    > a {
        background-color: @color-sidebar-hover;
        font-weight: bold;
    }
}

.sidebar a {
    line-height: 25px;
}

.sidebar li {
    a:hover,
    a:focus {
        background-color: @color-sidebar-hover;
    }
}

.sidebar .arrow {
    float: right;
}

.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active > a > .fa.arrow:before {
    content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: none !important;
}

.sidebar .nav-second-level li a {
    padding-left: 37px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

@media(min-width:768px) {
    .sidebar {
        z-index: 1;
        position: absolute;
        width: 250px;
        margin-top: 60px;
        background-color: @color-sidebar;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}
