// Flex variation
//
// Custom styles for additional flex alignment options.

each(map-keys(@grid-breakpoints), #(@breakpoint) {
	#media-breakpoint-up(@breakpoint, {
		@infix: breakpoint-infix(@breakpoint, @grid-breakpoints);

		.fs-flex@{infix}-row            { flex-direction: row !important; }
		.fs-flex@{infix}-column         { flex-direction: column !important; }
		.fs-flex@{infix}-row-reverse    { flex-direction: row-reverse !important; }
		.fs-flex@{infix}-column-reverse { flex-direction: column-reverse !important; }

		.fs-flex@{infix}-wrap         { flex-wrap: wrap !important; }
		.fs-flex@{infix}-nowrap       { flex-wrap: nowrap !important; }
		.fs-flex@{infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
		.fs-flex@{infix}-fill         { flex: 1 1 auto !important; }
		.fs-flex@{infix}-grow-0       { flex-grow: 0 !important; }
		.fs-flex@{infix}-grow-1       { flex-grow: 1 !important; }
		.fs-flex@{infix}-shrink-0     { flex-shrink: 0 !important; }
		.fs-flex@{infix}-shrink-1     { flex-shrink: 1 !important; }

		.fs-justify-content@{infix}-start   { justify-content: flex-start !important; }
		.fs-justify-content@{infix}-end     { justify-content: flex-end !important; }
		.fs-justify-content@{infix}-center  { justify-content: center !important; }
		.fs-justify-content@{infix}-between { justify-content: space-between !important; }
		.fs-justify-content@{infix}-around  { justify-content: space-around !important; }

		.fs-align-items@{infix}-start    { align-items: flex-start !important; }
		.fs-align-items@{infix}-end      { align-items: flex-end !important; }
		.fs-align-items@{infix}-center   { align-items: center !important; }
		.fs-align-items@{infix}-baseline { align-items: baseline !important; }
		.fs-align-items@{infix}-stretch  { align-items: stretch !important; }

		.fs-align-content@{infix}-start   { align-content: flex-start !important; }
		.fs-align-content@{infix}-end     { align-content: flex-end !important; }
		.fs-align-content@{infix}-center  { align-content: center !important; }
		.fs-align-content@{infix}-between { align-content: space-between !important; }
		.fs-align-content@{infix}-around  { align-content: space-around !important; }
		.fs-align-content@{infix}-stretch { align-content: stretch !important; }

		.fs-align-self@{infix}-auto     { align-self: auto !important; }
		.fs-align-self@{infix}-start    { align-self: flex-start !important; }
		.fs-align-self@{infix}-end      { align-self: flex-end !important; }
		.fs-align-self@{infix}-center   { align-self: center !important; }
		.fs-align-self@{infix}-baseline { align-self: baseline !important; }
		.fs-align-self@{infix}-stretch  { align-self: stretch !important; }
	});
});
