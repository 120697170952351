/* Borders */

.b-r {
	border-right: 1px solid @color-primary;

	&.b-r-9 {
		height: 90%;
		z-index: -1;
	}
}
.b-l {
	border-left: 1px solid @color-primary;

	&.b-l-9 {
		height: 90%;
		z-index: -1;
	}
}
.b-b {
	border-bottom: 1px solid @color-primary;
}
.b-b-lighten {
	border-bottom: 1px solid #ddd;
}
.b-b-md {
	border-bottom: 2px solid @color-primary;
}

.table-b-t-0 {
	border-top: 0;
}