.subject-encounter-input-group {
	width: 100%;
	.select, .input {
		border-color: rgb(204, 204, 204);
	}
}

.status-indicator {
	position: absolute;
	right: 20px;
	top: 15px;
	z-index: 2;
	border-radius: 10px;
	border: 1px solid #ccc;
    padding: 5px;
}

.subject-options-panel {
    padding-left: 20px;
}

.subject-timeline {
	.subject-timeline-loading {
		height: 100px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.first-time-loading {
		height: 400px;
	}
}

.nav-tabs-subjects {
	width: fit-content;
	margin-bottom: -1px;

	&.nav-tabs > li {
		> a {
			margin-right: unset;
		}

		&.active > a {
			background-color: whitesmoke;
		}
	}
}

.subject-view-mode-controls {
	position: absolute;
	right: 10px;
	top: -5px;
}

.info-header .info-panel .info-panel-title&.subject-info-main {
	max-width: 60%;
	min-width: unset;
}

.go-to-subject {
	align-self: end;
	margin-left: 10px;
	margin-bottom: 10px;
}

.group-subject-id {
	position: relative;
	.this-subject {
		position: absolute;
		right: 105%;
		top: 0;
	}
}

.events-list-end {
	font-size: smaller;
	color: gray;
}

.actions-button-height {
	height: 34px;
}

.arrow-disabled {
	color: gray;
	cursor: default;
	pointer-events: none;
}