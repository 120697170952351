.pie-graph-container {
	height: 100%;
}

.dashboard-more-container {
	position: relative;

	.dashboard-more {
		position: absolute;
		bottom: 10px;
		right: 25px;
	}    
}

.dashboard-graph-container {
	position: relative;

	.panel-body {
		padding-top: 0;

		.chart-position-container {
			height: 180px;
			width: 100%;
		}
	}
}

.statistics-page {
	&.statistics-panel {
		min-height: ~"calc(100% - 59px)";
	}
}

.statistics-panel {
	overflow: hidden;

	.panel-header {
		font-weight: bold;
	}
	.statistics-page-title {
		font-size: 18pt;
	}

	.panel-body {
		padding-top: 5px;

		.chart-position-container {
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-bottom: 10px;
		}
	}
}

.status-circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	display: inline-block; margin: 0 10px 0 5px;
}

.service-name {
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
}

.accordion-focusable .panel-group .panel .panel-heading .panel-title a{
	&:focus,
	&:hover {
		outline: none;
		text-decoration: none;
	}
}
