/* File input directive */

div.input-file input[type="file"] {
	/*display:none causes js issues*/
	position: fixed;
	left: -1000px;
	top: -1000px;
}

div.input-file input[type="text"] {
	margin-bottom: 5px;
}