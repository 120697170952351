.icon-fill-primary .icon {
	fill: @color-primary;
}

.icon-fill-white .icon {
	fill: #fff;
}

.icon-md-w {
	width: 20px;
}

.icon-o {
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: inherit;
	-webkit-text-fill-color: transparent;
}

.fill-width-icon {
	width: 100%;
}
