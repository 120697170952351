
.logs {
	width: 100%;
	overflow: auto;
	max-height: 250px;
}

.logs > ul {
	list-style: none;
	padding-left: 0px;
	border: solid 1px whitesmoke;
}

.logs > ul > li {
	background-color: white;
}

.logs > ul > li:nth-child(odd) {
	background-color: whitesmoke;
}

.logs > ul > li > .date {
	color: #008080;
	margin-left: 10px;
	padding-right: 10px;
	font-weight: bold;
}

.logs ul > li > .log {
	color: black;
}