/* Latent Editor */

.btn-progress {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

#editor-window {
	margin: 0;
	position: relative;
	width: 100%;
	height: calc(~"100vh - 220px");
	overflow: auto;
	background-image: url('../images/checkerboard.png');
}

#editor-canvas {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	transform-origin: left top;
}

#line-canvas {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8;
	transform-origin: left top;
}

#minutia-canvas {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	z-index: 7;
	transform-origin: left top;
}

#minutia-canvas-hires {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	z-index: 7;
	transform-origin: left top;
	pointer-events: none;
}

#curve-canvas {
	background-image: url('../images/grid_256.png');
}

#brush-svg, #ridge-tool-svg, #line-svg, #curve-svg {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	transform-origin: left top;
	pointer-events: none;
}

.sidebar-box {
	margin-bottom: 10px;
	border: 1px solid #000000;
	padding: 10px;
}

.latent-editor-title {
	display: block;
	padding: 0;
	margin: 0 0 10px 0;
	color: @color-primary;
}
.latent-editor-title.inline {
	display: inline;
	padding: 0;
	margin: 0 0 10px 0;
	color: @color-primary;
}

#file-upload-field {
	line-height: calc(~"100vh - 287px"); // 287px - heights of nav_bar + header + progress_path + footer
	vertical-align: middle;
}

#file-upload-button {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.brush-cell {
	width: 100%;
	text-align: center;
	padding: 10px;
}

#prev1, #prev2, #prev3, #prev4, #prev5, #prev6 {
	max-width: 100%;
	max-height: calc(~"(100vh - 250px) / 2");
}

.dynamic-canvas {
	width: 100%;
	height: auto;
}