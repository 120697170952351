/* Modalities */

.icon-modality {
	display: block;
}

.modality-wrapper {
	.col-lg-6 {
		padding-left: 0.5em;
		padding-right: 0.5em
	}
}

.modality {
	transition: 1.0s;
	background-color: @color-secondary;
	border: 2px solid @color-primary;
	padding-bottom: 20%;
	position: relative;
	margin-bottom: 1em;

	.modality-content {
		background-color: whitesmoke;
		border: 1px solid black;
		padding-bottom: 50%;
		position: relative;

		.content {
			font-size: 4.0em;
			color: @color-primary;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.modality-text {
		font-size: 1.5em;
		color: white;
		position: absolute;
		top: 85%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.modality-status {
		font-size: 1.5em;
		position: absolute;
		top: 85%;
		left: 85%;
		transform: translate(-50%, -50%);
		color: white;
	}
}

.modality:hover {
	background-color: lighten(@color-secondary, 10%);
	border: 2px solid @color-primary;
}