/* Directive: header-notification */

#header-notification {
	color: #000000;
	margin: 0px;
}
#header-notification > li > a {
	line-height: 30px;
	color: #ffffff;
	background-color: fade(@color-secondary, 15%);
}
#header-notification > li > a:hover, #header-notification > a:active {
	color: darken(#ffffff, 10%);
	background-color: fade(@color-secondary, 35%);
}