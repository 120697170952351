/* Page footer */

#footer {
	background-color: @color-primary;
	color: white;
	a {
		color: white;
	}
	ul {
		line-height: 50px;
		margin: 0px;
	}
}

#sticky-bottom {
	background-color: @color-primary;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50px;
}