.list-box-btns {
	height: 100%;
	width: max-content;
	margin: auto;

	div {
		width: 100%;
		+ div {
			margin-top: 0.5rem;
		}
	}
}

@media (max-width: @screen-xs-max) {
	.list-box-btns {
		height: auto;
		width: unset;
		margin: unset; 

		div {
			margin: 0.5rem;
			width: auto;
			+ div {
				margin-top: unset;
			}
		}
	}
}
