@notification-height: 57px;
@number-to-show: 5;

#notifications-dropdown .count {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 16px;
	width: 16px;
	overflow: hidden;
	background: @color-danger;
	color: #FFF;
	text-align: center;
	border-radius: 100%;
	font-size: 11px;
	font-weight: bold;
	line-height: 16px;
}

#notifications-dropdown {
	width: 48px;
}

#notifications .notification {
	height: @notification-height;
	position: relative;
	.footer-left {
		position: absolute;
		right: 5px;
		top: 25px;
		height: 30px;
		color: grey;
		overflow: hidden;
	}
	.footer-right {
		position: absolute;
		left: 15px;
		top: 25px;
		height: 30px;
		color: grey;
		overflow: hidden;
	}
	.status {
		position: absolute;
		right: 5px;
		top: 0;
	}
}

#notifications .items {
	overflow-y: auto;
	max-height: calc(@notification-height * @number-to-show);
}

#notifications .header {
	border-bottom: 1px solid darken(whitesmoke, 12%);
}

#notifications .footer {
	border-top: 1px solid darken(whitesmoke, 12%);
}

#notifications .items ul li:first-child {
	/*border-bottom: 1px solid darken(whitesmoke, 12%);*/
}

#notifications .items ul li:not(:last-child) {
	border-bottom: 1px solid darken(whitesmoke, 12%);
}

#notifications .not-seen {
	background-color: whitesmoke;
}

.notification:hover {
	background-color: whitesmoke;
	cursor: pointer;
}

#notifications button {
	outline: 0;
}

#toast-container {
	margin-top: 60px;
}

.toast {
	width: 500px !important;
}

// https://github.com/Foxandxss/angular-toastr/issues/158
#toast-container {
	pointer-events: none !important;
	.toast {
		pointer-events: auto;
	}
}

// Scrollbar
#notifications {
	::-webkit-scrollbar {
		width: 9px;
	}

	::-webkit-scrollbar-track {
		background: @color-secondary;
		border: 4px solid transparent;
		background-clip: content-box;   // this is important
	}

	::-webkit-scrollbar-thumb {
		background: @color-secondary;
		border: 1px solid rgb(0,0,0);
	}
}