.nav-vertical {
	border-bottom: unset;
	li {
		a {
		border: unset;
		border-radius: unset;
		color: black !important;

			&:hover {
				border-color: unset;
				color: @color-primary !important;
			}
		}

		.nav-second-level a {
			padding-left: 37px;
		}

		&.active > a {
			&,
			&:hover,
			&:focus {
				color: @color-primary;
				font-weight: bold;
				background-color: @nav-tabs-active-link-hover-bg;
				border: unset;
				border-bottom-color: unset;
			}
		}
	}
}