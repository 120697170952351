.row-equal-height-cols {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
}
.col-equal-height {
	flex-direction: column;
}

.row-xs-flex-center {
	display:flex;
	align-items:center;
}
@media ( min-width:768px ) {
	.row-sm-flex-center {
		display:flex;
		align-items:center;
	}
}
@media ( min-width: 992px ) {
	.row-md-flex-center {
		display:flex;
		align-items:center;
	}
}
@media ( min-width: 1200px ) {
	.row-lg-flex-center {
		display:flex;
		align-items:center;
	}
}

.row-eq-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display:         flex;
}

.flex-row-container-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.flex-row-container-bottom-right {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
}

.auto-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding-left: 0;
	padding-right: 0;
  
	> div {
		flex: 1 1 auto;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
}