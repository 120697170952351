// daterangepicker styles
@import (less) '~daterangepicker/daterangepicker.css';

@range-primary: lighten(@color-primary, 10%);
@range-secondary: lighten(@color-primary, 50%);

.daterangepicker {
    td {
        &.in-range {
            background-color: @range-secondary;
        }

        &.active,
        &.active:hover {
            background-color: @range-primary
        }
    }

    .ranges {
        li {
            &.active {
                background-color: @range-primary;
            }
        }
    }
}