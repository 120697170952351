
.requirements span {
	font-size: 12px;
}

.requirements i {
	font-size: 12px;
}

.completed {
	color: #224d58;

} 

.incompleted {
	color: brown;
} 

