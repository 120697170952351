.eye-container {
	background: whitesmoke;
	padding: 50px 0;
}

.single-eye {
	max-width:300px;
	display: flex;
	flex-direction: row;
	justify-self: center;
	align-items: center;

	&.eye-left {
		margin-right: 10px;
	}

	&.eye-right {
		margin-left: 10px;
	}
}
