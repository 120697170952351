.file-drop-container { 
	height: 100px;
}

.file-drop-area {
	background-color: white;
	border: 2px dashed grey;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 0;
	position: relative;
}

.file-drop-area.line-height-100 div,
.file-drop-area label {
	line-height: 100px;
}

.file-drop-area.line-height-60 div:first-child {
	line-height: 60px;
}

input.input-file[type='file'] {
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
	z-index: 128;
	margin-top: -100px;
}