.thresholds-group {
    width: 85%;
}

.thresholds-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .parameter-container {
        margin-right: 5px;
        margin-bottom: 5px;
    }

    hr {
        margin: 5px 0;
        flex-basis: 100%;
    }
}

.threshold-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 5px 5px 0;
}

.score-panel {
	border: 1px solid #ddd;
	border-radius: 5%;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;

	padding: 5px;

	.face-container,
	.finger-container {
        max-width: 50px;
	}

	.score-img-container {
        position: relative;
        padding: 10px;

		.score-icon {
			font-size: 30px;
			position: relative;
		}
	}

	.score-info {
		position: relative;

		.info-modality {
			font-weight: bold;
		}
	}
}

.parameter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 20px;
    flex: 1 0 200px;
    transition: border .3s;

    &.parameter-active {
        border: 2px solid @brand-primary;
    }

    padding: 10px 10px 10px 5px;
    .parameter-icon {
        font-size: 30pt;
        padding: 0 10px;
    }

    .parameter-content {
        .parameter-title {
            font-weight: bold;
        }

        .parameter-input-groups {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: center;

            .parameter-input-group {
                .parameter-input-addon {
                    padding: 0 5px;
                    display: inline;
                    vertical-align: bottom;

                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }

                .parameter-input-far {
                    font-style: italic;
                    font-size: small;
                    color: #767676;
                    white-space: nowrap;
                }

                /* to override Chrome specifity */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                .parameter-input {
                    border:none;
                    background-image:none;
                    background: none;
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    -moz-appearance: textfield;
                    padding: 0 5px;
                    margin: 0 5px;
                    margin-right: 5px;

                    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
                    transition: all .3s ease;
                    &:focus {
                        border-bottom: 1px solid @brand-primary;
                        outline: none;
                    }

                    &.has-error {
                        border-bottom: 2px solid lighten(@brand-danger, 5%);
                    }
                }
            }
        }
    }
}

.additional-parameters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    * {
        flex-basis: 50%;
    }
}

.alerts-condensed {
    .alert {
        margin-bottom: 0;
    }
}

.threshold-description {
    font-size: initial;

    .threshold-box {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        border: 2px solid #ddd;
        border-radius: 20px;

        &.active {
            border-color: @brand-primary;
        }
    }
}

.thresholds-title {
    width:85%
}