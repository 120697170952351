.subject-navigation-container {
    position: relative;

    .subject-navigation-buttons {
        width: 100%;
    }

    .subject-navigation {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        bottom: 10px;
        z-index: 10;
        max-width: 100%;

        .select {
            max-width: 80%;
            border: 1px solid darkgray;
            border-image: initial;
            cursor: pointer;
        }

        .prev, .next {
            i {
                font-size: 20pt;
                color: @color-primary;
                vertical-align: middle;
            }
        }

        .prev {
            padding: 3px 10px 3px 5px;
            border-radius: 50% 0 0 50%;
        }
        .next {
            padding: 3px 5px 3px 10px;
            border-radius: 0 50% 50% 0;
        }
    }
}