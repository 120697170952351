/* ICAO */
.icao-statuses {

	ul {
		&.face-icao {
			height: 365px;
		}
		&.orientation-icao {
			height: 169px;
		}
		&.position-icao {
			height: 169px;
		}
		&.img-quality-icao {
			height: 133px;
		}
	}

	li {
		font-size: 12px;
	}

	.table {
		margin-bottom: 0;
	}

	.item-group {

		.item-invalid {
			color: #721c24;
		}
		.item-skipped {
			color: #856404;
		}
		.item-valid {
			color: #0c5460;
		}
		.fa {
			font-size: 15pt;
		}
	}

	div {
		font-weight: bold;
	}

	.face-icon {
		width: 20px;
		float: left;
	}

	.face-text {
		vertical-align: middle;
	}

	.item-icon {
		font-size: 14pt;
	}
	.item-external-icon {
		width: 14pt;
	}
}