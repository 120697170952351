@import (less) '~angularjs-slider/dist/rzslider.css';

.range-wrap {
	.rz-pointer {
		width: 20px;
		height: 20px;
		background: @color-secondary;
		transform: translate(0px, 5px);

		&.rz-active {
			&::after {
				background: lightgray;
			}
		}

		&::after {
			transform: translate(-5px, -5px);
			width: 6px;
			height: 6px;
		}
	}

	.rz-bar-wrapper {
		.rz-bar {
			background: fade(@color-primary, 15%);
		}
	}
}