.results-view-buttons-container {
    position: relative;
}

.results-view-buttons {
    border-radius: 20px;

    &:focus {
        background-color: unset;
        outline: unset;
    }
}

.view-compare,
.view-list {
    vertical-align: middle;
    color: inherit;
}

.table-hover {
    tbody tr {
        .visible-on-hover {
            opacity: 0;
            transition: opacity 0;
        }

        &:hover .visible-on-hover {
            opacity: 1;
            transition: opacity 0.3s;
        }
    }
}