/* Positioning */

.img-center {
	margin: 0 auto;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.word-break {
	word-break: break-all;
}

.tooltip {
	z-index: 1050;
}

.pos-rel {
	position: relative;
}
.pos-abs-b-r {
	position: absolute;
	bottom: 0;
	right: 0;
}

.p-b-center {
	position: absolute;
	bottom: 0;
	left: 1%;
	right: 1%;
	width: 98%;
}

/* Spacing */

.spacer-t {
	border-top: 1px solid @color-primary;
	margin-bottom: 15px;
}

/* Margins */

.m-t-tiny {
	margin-top: 1px;
}
.m-b-p-tiny {
	margin-bottom: 1%;
}
.m-l-p-tiny {
	margin-left: 1%;
}
.m-r-p-tiny {
	margin-right: 1%;
}
.m-0-auto {
	margin: 0 auto !important;
}
.m-t-m-xs {
	margin-top: -@xs;
}
.m-t-m-md {
	margin-top: -@md;
}

/* Paddings */

.p-l-tiny {
	padding-left: 1px;
}

.p-v-xs {
	padding: 5px 0px;
}
.p-v-sm {
	padding-top: 5px;
	padding-bottom: 5px;
}
.p-v-lg {
	padding-top: 15px;
	padding-bottom: 15px;
}
.p-h-lg {
	padding-left: 15px;
	padding-right: 15px;
}
.p-b-sm {
	padding-bottom: 5px;
}
.p-b-lg {
	padding-bottom: 15px;
}
.p-t-sm {
	padding-top: 5px;
}
.p-l-p-tiny {
	padding-left: 1%;
}
.p-r-p-tiny {
	padding-right: 1%;
}

.flex {
	display: flex;

	// MS Edge specific rules
	@supports (-ms-ime-align: auto) {
		// The pre-chromium version of MS Edge by default uses align-items: stretch
		align-items: center;
	}

	&.flex-wrap {
		flex-wrap: wrap;
	}

	&.flex-col {
		flex-direction: column;
	}
	&.flex-row {
		flex-direction: row;
	}

	&.space-around {
		justify-content: space-around;
	}
	&.space-between {
		justify-content: space-between;
	}
	&.space-center {
		justify-content: center;
	}

	.flex-grow-12 {
		flex-grow: 1;
	}
}

.row.equal {
	display: -webkit-flex;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.no-gutters-left {
	padding-left: 0px;
}
.no-gutters-right {
	padding-right: 0px;
}
@media (max-width: @screen-md-min) {
	.no-gutters-left {
		padding-left: 15px;
	}
	.no-gutters-right {
		padding-right: 15px;
	}
}

.align-middle-container {
	align-items: center;
}
.align-middle {
	vertical-align: middle;
	align-self: center;
}

.align-self-center {
	align-self: center;
}
.align-self-start {
	align-self: flex-start;
}

.table-vertical-middle {
	> thead > tr > th {
		vertical-align: middle;
	}
	> tbody > tr > td {
		vertical-align: middle;
	}
}

.table-align-middle {
	> thead > tr > th {
		text-align: center;
		vertical-align: middle;
	}
	> tbody > tr > td {
		text-align: center;
		vertical-align: middle;
	}
}

.full-height {
	height: 100%;
}
