/* Block UI */

.block-ui-message {
	background-color: @color-primary;
	border-radius: 0px;
}
.block-ui-controls {
	text-align: right;
}

.block-ui-container {
	position: fixed;
}