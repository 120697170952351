.carousel-control { width:  4%; }
.carousel-control.left, .carousel-control.right { background-image: none; }
.carousel-control {
    .glyphicon {
        transform: translateY(-50%);
        margin-top: unset;
    }
}

.custom-indicators {
    .carousel-indicators {
        bottom: -16px;
        li {
            display: inline-block;
            width: 40px;
            height: 2px;
            margin: 1px;
            text-indent: -999px;
            border: unset;
            cursor: none;
            background-color: unset;
        };
        .active {
            margin: 0;
            width: 30px;
            height: 2px;
            background-color: @color-primary;
        }
    }
}
.no-indicators {
    .carousel-indicators {
        display: none;
    }
}
.persist-controls {
    .carousel-control {
        display: block !important;
    }
}


.carousel-slider {
    @slider-height: 6px;
    z-index: 15;
    height: @slider-height;
    width: 100%;
    margin-top: 2px;

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: @slider-height;
        border: @slider-height/2 solid whitesmoke;
        border-radius: 10px;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;

        &:hover {
            opacity: 1;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            height: @slider-height;
            border: @slider-height/2 solid @color-primary;
            border-radius: 10px;
            cursor: pointer;
        }

        &::-moz-range-thumb {
            width: 100%;
            height: @slider-height;
            background: @color-primary;
            border: none;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}

.carousel-inner {
    .index-panel {
        position: relative;
        color: @color-primary;
        font-weight: bold;
        font-size: 125%;

        border: 1px solid #ddd;
        border-radius: 5%;

        .slide-warning {
            overflow: auto;
            position: absolute;
            top: 0;
            right: 0;

            .warning-icon,
            .danger-icon,
            .success-icon {
                z-index: 100;
                position: relative;
                font-size: 11pt;
                padding: 3px;

                &.active {
                    color: white;
                }
            }

            .warning-icon {
                color: @brand-warning;
            }

            .success-icon {
                color: @brand-success;
            }

            .danger-icon {
                color: @brand-danger;
            }

            .warning-exclamation-mark {
                z-index: 0;
                background-color: black;
                position: absolute;
                top: 0;
                right: 0;
                width: 4px;
                height: 11px;
                border-radius: 100%;
                transform: translate(-6px, 4px);
            }
        }
    }
    .slide-duplicate {
        background-color: lighten(@brand-danger, 5%);
        color: white;
    }
    .slide-unique {
        background-color: lighten(@brand-success, 5%);
        color: white;
    }

    .slide-conflict-bordered {
        outline: medium solid lighten(@brand-warning, 5%);
        outline-offset: -3px;
    }

    .slide-selected {
        background-color: lighten(@color-primary, 15%);
        color: white;
        outline: medium solid @color-primary;
        outline-offset: -3px;

        &.slide-unique {
            background-color: lighten(@brand-success, 5%);
        }
        &.slide-duplicate {
            background-color: lighten(@brand-danger, 5%);
        }

        &.slide-conflict-bordered {
            outline: medium solid lighten(@brand-warning, 5%);
            outline-offset: -3px;
        }
    }
}

@media screen and (min-width: 768px) {
    .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev { margin-left: -20px }
    .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev { margin-right: -20px }
}

@media (max-width: 768px) {
    .carousel-inner .active.left { left: -100%; }
    .carousel-inner .next        { left:  100%; }
    .carousel-inner .prev		 { left: -100%; }
    .active > div { display:none; }
    .active > div:first-child { display:block; }
}

@media (min-width: 768px) and (max-width: 992px ) {
    .carousel-inner .active.left { left: -50%; }
    .carousel-inner .next        { left:  50%; }
    .carousel-inner .prev		 { left: -50%; }
    .active > div { display:none; }
    .active > div:first-child { display:block; }
    .active > div:first-child + div { display:block; }
}

@media (min-width: 992px ) {
    .carousel-inner .active.left { left: -25%; }
    .carousel-inner .next        { left:  25%; }
    .carousel-inner .prev		 { left: -25%; }
}

.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }

.slide-margin {
    margin-left: 5px;
}