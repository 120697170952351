.column-content {
    position: relative;
    
    &::after {
        content: attr(data-column-content) " ";
        opacity: 0;
    }

    .data {
        position: absolute;
        z-index: 100;
    }
}