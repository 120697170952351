.capture-qualities {
    margin: auto;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;

    .capture-quality {
        .capture-label {
            vertical-align: bottom;
            font-size: 9pt;
            font-weight: 600;
            letter-spacing: 1px;
            padding-right: 5px;
            padding-bottom: 0;
            text-align: left;
        }
        .capture-stars {
            i {
                color: @color-primary;
            }
        }
    }
}

.finger-header {
    position: relative;
    .quality-card {
        position: absolute;
        bottom: 0px;
        right: 0;
        padding: 0 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        color: black;

        .quality-icon {
            color: @color-indicator;
            display: block;
        }

        .quality-score {
            padding-left: 5px;
        }
    }
}

img,
canvas {
    &.blur {
        filter: blur(@image-blur);
    }
}

.img-wrapper {
    overflow: hidden;

    img,
    canvas {
        &.blur {
            filter: blur(@image-blur);
        }
    }
}